import styled from 'styled-components'
import { Col } from 'reactstrap'

export const ScopeCol = styled(Col)`
  min-height: 185px;
  align-items: center;
  display: inherit;
  object-fit: cover;
  padding: 0 18px;
`

export const ScopeImg = styled.img`
  width: auto;
  max-width: 120%;
  margin-left: -20%;
  height: auto;
  object-fit: cover;
  @media only screen and (max-width: 640px) {
    max-width: 90%;
    margin-left: 5%;
    margin-top: 30px;
  }
`
