import styled, { css } from 'styled-components'
import { classAnimateFadeIn } from '../../utils'
export const Image = styled.img.attrs({
  class: classAnimateFadeIn
})`
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  max-height: 17vw;
  max-width: 17vw;

  /* Extra small devices (phones, 640px and down) */
  @media only screen and (max-width: 640px) {
    max-height: 35vw;
    max-width: 35vw;
  }
`
export const Grid = styled.div`
  display: inline-grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(3, 1fr);
  width: 100%;
  height: 100%;
  margin-top: 60px;
  background-color: white;

  /* Extra small devices (phones, 640px and down) */
  @media only screen and (max-width: 640px) {
    height: 80%;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(6, 1fr);
    background-color: white;
    height: auto;
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
  }

  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
  }
`

export const BannerReport = styled.section`
  /* background: #ffff; */
  margin-top: 100px;
  width: 100%;
  /* height: 100%; */
  padding: 53px 8px;
  ${props =>
    props.bgImg &&
    css`
      background-image: url(${props.bgImg});
      width: 100%;
      height: 100%;
      background-size: 100% auto;
      background-position: center;
      object-fit: cover;
      box-shadow: inset 0 0 0 1000px rgb(0 0 0 / 50%);
      background-size: cover;
      /* width: 100%;
     height: auto; */
    `}

  /* Extra small devices (phones, 640px and down) */
  @media only screen and (max-width: 640px) {
    height: 0;
    margin-top: 29px;
  }
`
export const BannerWhite = styled.section`
  background: #ffffff;
  width: 100%;
  height: 66px;
  margin-top: 0px;
  margin-bottom: -36px;
  padding: 25px;
`

export const BannerReportText = styled.label`
  color: #fff;
  font-family: 'Baloo 2';
  text-align: center;
  font-size: 88px;
  line-height: 88px;
  padding: 10px;
  font-weight: bold;
  width: 100%;

  /* Extra small devices (phones, 640px and down) */
  @media only screen and (max-width: 640px) {
    font-size: 32px;
    margin-top: -50px;
  }
`
export const BannerReportTextGreen = styled.span`
  font-family: 'Baloo Tammudu 2', cursive;
  color: #33cc99;
`

export const Item = styled.div`
  ${props =>
    props.color &&
    css`
      background: ${props.color};
    `}

  width: 100%;
  height: 100%;
  //grid-area: prd-sec;
  max-width: 17vw;
  max-height: 17vw;
  position: relative;

  /* Extra small devices (phones, 640px and down) */
  @media only screen and (max-width: 640px) {
    max-width: 35vw;
    max-height: 35vw;
    ${props =>
      props.colorResp &&
      css`
        background: ${props.colorResp};
      `}
  }
`

export const P = styled.p`
  vertical-align: center;
  margin-top: 50%;
  transform: translateY(-50%);
  font-size: 40px;
  color: #1c3651;
  ${props =>
    props.align &&
    css`
      text-align: ${props.align};
    `}
`
export const P1 = styled.p`
  vertical-align: center;
  font-family: 'Baloo 2';
  margin-top: 50%;
  transform: translateY(-50%);
  font-size: 48px;
  color: #1c3651;
  padding: 0px;
  margin: 40% 0px 0px 25px;
  ${props =>
    props.align &&
    css`
      text-align: ${props.align};
    `}
  /* Extra small devices (phones, 640px and down) */
  @media only screen and (max-width: 640px) {
    font-size: 26px;
    margin: 45px 0px 0 6px;
  }
`
export const P2 = styled.p`
  vertical-align: center;
  font-family: 'Baloo 2';
  margin-top: 1px;
  transform: translateY(-50%);
  font-size: 35px;
  padding: 0px;
  margin: -19px 0px 0px 25px;
  color: #1c3651;
  ${props =>
    props.align &&
    css`
      text-align: ${props.align};
    `}

  /* Extra small devices (phones, 640px and down) */
  @media only screen and (max-width: 640px) {
    font-size: 26px;
    margin: -10px 0px 0 6px;
  }
`
