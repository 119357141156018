import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Nav, Navbar } from 'react-bootstrap'
import styled from 'styled-components'
import { NavDropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom'


export const NavbarContainer = styled.nav`
  width: 100%;
  height: ${props => (props.extendNavbar ? '100vh' : '70px')};
  background-color: #1c3651;
  padding: 10px 52px;
  top: 0;
  z-index: 1;
  position: fixed;
  display: block;
  transition: top 0.3s;
  @media (min-width: 700px) {
    height: 60px;
  }
`

export const LabelLinkResponsive = styled.label`
  @media (max-width: 700px) {
    font-family: 'Montserrat', sans-serif;
    color: #fff;
    font-size: 18px;
    cursor: pointer;
  }
`
export const LabelLink = styled.label`
  font-family: 'Montserrat', sans-serif;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  .dropdown-item {
    color: #fff !important;
    background-color: #22466a;
  }
  @media (max-width: 700px) {
    display: none;
  }
`
export const LabelLinkTitle = styled.label`
  font-family: 'Montserrat', sans-serif;
  color: #fff;
  font-size: 17px;
  margin-top: 0px;
  margin-left: 16px;
  padding-right: 30px;
  padding-bottom: 2px;
  margin-right: 16px;
  font-weight: bold;
  cursor: pointer;
`

export const MediaQueryNotMobile = styled.div`
  padding: 0px;
  display: inline-flex;
  margin: 0px;
  @media (max-width: 768px) {
    display: none;
  }
`

export const MediaQueryInMobile = styled.div`
  padding: 0px;
  background-color: #1c3651;
  display: contents;
  margin: 0px;
  @media (min-width: 768px) {
    display: none;
  }
`

export const IconNavBar = styled(FontAwesomeIcon)`
  font-size: 30px;
  color: #fff;
  margin-right: 5px;
  @media (max-width: 768px) {
    position: absolute;
    left: 45px;
  }
`

export const LogoNavBar = styled.img`
  position: absolute;
  width: 130px;
  left: 100px;
  margin-top: -15px;
  @media (max-width: 768px) {
    left: 15px;
  }
`
export const MenuNavBar = styled(Nav)`
  width: 100%;
  padding-bottom: 30px;
  background-color: #1c3651;
`

export const MenuNavDropdown = styled(NavDropdown)`
  font-family: 'Montserrat', sans-serif;
  color: #fff !important;
  font-size: 18px;
  cursor: pointer;
  @media (max-width: 700px) {
    display: none;
  }

  .nav-link {
    color: #fff !important;
    font-weight: normal;
  }

  .dropdown-menu {
    background-color: #22466a;
    color: #fff !important;
  }

  .dropdown-menu[data-bs-popper] {
    /* left: -45px; */
  }
  .dropdown-item {
    color: #fff !important;
    background-color: #22466a;
  }
  .dropdown-item:hover {
    color: #fff;
    background-color: #294f74;
  }
`

export const MenuNavDropdownFullDevice = styled(NavDropdown)`
  font-family: 'Montserrat', sans-serif;
  color: #fff !important;
  font-size: 18px;
  cursor: pointer;

  .nav-link {
    color: #1c3651 !important;
  }

  .dropdown-menu {
    background-color: #1c3651;
    color: #fff !important;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 58px;
    z-index: 1;
  }

  .dropdown-menu[data-bs-popper] {
    /* left: -45px; */
  }
  .dropdown-item {
    color: #fff !important;
  }
  .dropdown-item:hover {
    color: #fff;
    background-color: #294f74;
  }
`

/// Others styles

export const LeftContainer = styled.div`
  flex: 50%;
  display: flex;
  align-items: center;
`

export const RightContainer = styled.div`
  flex: 50%;
  display: flex;
  justify-content: end;

  /* // everything bigger than that */
  @media only (min-width: 768px) and (max-width: 992px) {
    margin-right: 1%;
  }

  /* // everything bigger than that */
  @media only (min-width: 1900px) {
    margin-right: 3%;
  }
`

export const NavbarInnerContainer = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
`

export const NavbarLinkContainer = styled.div`
  display: flex;
`

export const NavbarLinkLogo = styled(Link)`
  color: white;
  font-size: small;
  font-weight: 400;
  text-transform: uppercase;
  text-decoration: none;
  margin: 10px 9px;
`

export const NavbarLinkProfile = styled(Link)`
  color: white;
  font-size: small;
  font-weight: 400;
  text-transform: uppercase;
  text-decoration: none;
  margin: 5px 5px;

  @media (max-width: 700px) {
    position: absolute;
    top: 28px;
    right: 70px;
  }
`

export const NavbarLink = styled(Link)`
  color: white;
  font-size: small;
  font-weight: 400;
  text-transform: uppercase;
  text-decoration: none;
  margin: 10px 7px;

  @media (max-width: 700px) {
    display: none;
  }
`

export const LinkMenu = styled.li`
  list-style: none;
`

export const NavItem1 = styled.ul`
  list-style: none;
  padding-left: 0;
  margin: 0;
`

export const NavItem = styled.li`
  list-style: none;
`

export const NavbarLinkDropdown = styled(Link)`
  color: white;
  font-size: small;
  font-weight: 400;
  text-transform: uppercase;
  text-decoration: none;
  margin: 1px;

  &:hover {
    color: #1c3651;
    font-weight: 400;
  }

  @media (max-width: 700px) {
    display: none;
  }
`

export const NavbarLinkExtended = styled.a`
  color: white;
  font-size: large;
  font-weight: 400;
  text-transform: uppercase;
  text-decoration: none;
  margin: 4px;
`

export const NavbarLinkExtendedMin = styled.a`
  color: white;
  font-size: medium;
  font-weight: 300;
  text-transform: uppercase;
  text-decoration: none;
  margin: 5px 0px 0px 0px;
`

export const LinkTitleMobile = styled.label`
  color: white;
  font-size: large;
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: none;
  margin: 2px 0px 0px 0px;
`

export const Logo = styled.img`
  max-width: 145px;
  height: auto;
  margin-bottom: 40px;
  @media (max-width: 768px) {
    position: relative;
    right: 40px;
    top: 1px;
  }
`

export const OpenLinksButton = styled.button`
  width: 70px;
  height: 50px;
  background: none;
  border: none;
  color: white;
  font-size: 45px;
  cursor: pointer;
  position: absolute;
  top: -7px;
  right: 5px;

  @media (min-width: 700px) {
    display: none;
  }
`

export const NavbarExtendedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  @media (min-width: 700px) {
    display: none;
  }
`

export const NavbarItemDropdown = styled.div`
  color: white;
  font-size: small;
  font-weight: 400;
  text-transform: uppercase;
  text-decoration: none;
  margin: 10px;
  @media (max-width: 700px) {
    display: none;
  }
`

export const NavbarDropdown = styled.div`
  position: relative;
  display: inline-block;
  &:hover {
    display: block;
    > div {
      display: block;
    }
  }
  @media (max-width: 700px) {
    display: none;
  }
`

export const NavbarDropdownContent = styled.div`
  display: none;
  position: absolute;
  top: 28px;
  background-color: #608fb8;
  min-width: 188px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0, 2);
  border-top: 5px solid #a3c3d9;
  padding: 5px 10px;
  z-index: 10;
  left: 10px;
`
