import styled from 'styled-components'
import { CardGroup, Card, CardImg, CardTitle } from 'reactstrap'
import { Col, Row } from 'reactstrap'

export const CivilGroup = styled(CardGroup)`
  @media (max-width: 640px) {}`

export const CardContainer = styled(Card)`
  border-radius: 20px;
  margin-top: -45%;
  height: 70%;
  padding-right: 20px;
  @media (max-width: 640px) {
    padding-right: 5px;
    height: auto;
    padding-bottom: 10px;
  }
`

export const CardImgBorder = styled(CardImg)`
  border-radius: 20px;
`

export const CircleImg = styled.img`
  background: #fff;
  width: 170px;
  height: 170px;
  margin-top: -15%;
  border-radius: 50%;
  margin-left: 53%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  @media (max-width: 640px) {
    width: 100px;
    height: 100px;
    margin-left: 59%;
  }
`
export const TitleCard = styled(CardTitle)`
  color: '#FF8300' !important;
`
export const HeaderContainer = styled.div`
  margin-left: 20px;
  margin-top: -10%;
`

export const CivilCol = styled(Col)`
  padding: 10px 20px;
  @media (max-width: 640px) {
    padding: 10px 50px;
    width: 100%;
  }
`

export const CivilRow = styled(Row)`
  margin-bottom: 15%;
  margin-top: 5%;
  @media (max-width: 640px) {
    display: flex;
    flex-direction: column;
  }
`
