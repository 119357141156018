import React from 'react'
import { Col, Row } from 'reactstrap'
import styled from 'styled-components'

export const AllianceImage = styled.img`
  width: 100%;
  @media (min-width: 768px) {
    padding: 20px 15%;
    margin-bottom: 35px;
  }
`

export const AllianceImageCol = styled(Col)`
  min-height: 185px;
  align-items: center;
  display: inherit;
  object-fit: cover;
  padding: 0 18px;
  @media (max-width: 500px) {
    padding: 0 20%;
  }
`

const AlliancesStrategics = ({ alliancesStrategics }) => {
  return (
    <Row
      sm={1}
      xs={1}
      md={1}
      lg={4}
      className={'animate__animated  animate__fadeIn animate__faster'}>
      {alliancesStrategics?.map(
        ({ alliancesStrategicsItemsThumbnail, alliancesStrategicsItemsName }) => (
          <AllianceImageCol>
            <AllianceImage
              src={alliancesStrategicsItemsThumbnail}
              alt={alliancesStrategicsItemsName}
            />
          </AllianceImageCol>
        )
      )}
    </Row>
  )
}

export default AlliancesStrategics
