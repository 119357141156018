import React from 'react'
import { Row } from 'reactstrap'
import { classAnimateFadeIn } from '../../utils'
import AllianceIntern from './AllianceIntern'

const AlliancesInterns = ({ alliancesInterns }) => {
  return (
    <Row
      lg={4}
      className={`justify-content-center ${classAnimateFadeIn}`}
      style={{ marginTop: '28px' }}>
      {alliancesInterns?.map((intern, index) => {
        return <AllianceIntern key={index} {...intern} />
      })}
    </Row>
  )
}

export default AlliancesInterns
