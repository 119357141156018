import styled from 'styled-components'
import { Nav, NavLink } from 'reactstrap'

export const NavTabs = styled(Nav)`
  @media (max-width: 640px) {
    flex-wrap: unset;
  }
`
export const NavLinkTab = styled(NavLink)`
  font-family: 'Baloo 2';
  @media (max-width: 640px) {
    width: 100px !important;
    height: 40px !important;
    padding: 10.5px 13px 11.5px 11px !important;
    font-size: 10px;
  }
`
export const NavLinkTabAlliances = styled(NavLink)`
  padding-top: 14px;
  font-size: 18px;
  @media (max-width: 640px) {
    width: 100px !important;
    height: 40px !important;
    padding: 10.5px 13px 11.5px 11px !important;
    font-size: 10px;
  }
`
