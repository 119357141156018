import Home from './../Pages/Home'
import Team from '../Pages/Team'

export const routes = [
  {
    path: '/',
    component: Home
  },
  {
    path: '/team/2021/es',
    component: Team
  },
  {
    path: '/team/2020/es',
    component: Team
  },
  {
    path: '/team/2021/en',
    component: Team
  },
  {
    path: '/team/2020/en',
    component: Team
  },
  {
    path: '/team/2021/pt',
    component: Team
  },
  {
    path: '/team/2020/pt',
    component: Team
  },
  {
    path: '/2021/es',
    component: Home
  },
  {
    path: '/2021/en',
    component: Home
  },
  {
    path: '/2021/pt',
    component: Home
  },
  {
    path: '/2020/es',
    component: Home
  },
  {
    path: '/2020/en',
    component: Home
  },
  {
    path: '/2020/pt',
    component: Home
  }
]
