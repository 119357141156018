import React from 'react'
import PropTypes from 'prop-types'
import { VolunteerLabelList } from './VolunteerStyled'

const VolunteersList = ({ name }) => {
  return <VolunteerLabelList>{name}</VolunteerLabelList>
}

VolunteersList.propTypes = {
  name: PropTypes.string.isRequired
}

export default VolunteersList
