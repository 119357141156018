import React from 'react'
import PropTypes from 'prop-types'
import { Col } from 'reactstrap'
import styled from 'styled-components'

export const AllianceInternContainer = styled.section`
  display: grid;
  justify-content: center;
  justify-items: center;
  margin: 0px 0px 50px 0px;
`

export const AllianceInternImg = styled.img`
  width: 146px;
  height: 149px;
  object-fit: cover;
  justify-self: left;
  border-radius: 50%;
  @media (max-width: 640px) {
    width: 100px;
    height: 100px;
  }
`

export const AllianceInternName = styled.p`
  font-family: Montserrat;
  font-size: 18px;
  width: 100%;
  line-height: 22px;
  font-weight: bold;
  margin: 8px 0 0 0px;
  text-align: left;
  color: #1c3651;
  @media (max-width: 640px) {
    font-size: 10px;
    line-height: 10px;
    padding: 0 10px 0 0;
  }
`
export const AllianceInternText = styled.p`
  width: 100%;
  font-family: Montserrat;
  font-size: 17px;
  line-height: 20px;
  font-weight: normal;
  margin: 0px;
  text-align: left;
  color: #1c3651;
  @media (max-width: 640px) {
    font-size: 8px;
    line-height: 10px;
    padding: 0 20px 0 0;
  }
`

const AllianceIntern = ({
  alliancesInternsItemsImage,
  alliancesInternsItemsName,
  alliancesInternsItemsDescription,
  alliancesInternsItemsLocation
}) => {
  return (
    <Col>
      <AllianceInternContainer>
        <AllianceInternImg src={alliancesInternsItemsImage} />
        <AllianceInternName>{alliancesInternsItemsName}</AllianceInternName>
        <AllianceInternText>{alliancesInternsItemsDescription}</AllianceInternText>
        <AllianceInternText>{alliancesInternsItemsLocation}</AllianceInternText>
      </AllianceInternContainer>
    </Col>
  )
}

AllianceIntern.propTypes = {}

export default AllianceIntern
