import React, { useState } from 'react'
import { Row } from 'reactstrap'
import Institution, { InstitutionCol, LastInstitutionContainer } from './Institution'
import styled from 'styled-components'
import { ContactSubtitleInstitutions } from '../Footer/FooterStyled'
import { LinkTo, InstitutionSpanSubtitle } from '../StyledComponents/styled'

const InstitutionRow = styled(Row)`
  background: #fff;
  padding: 0;
`

const InstitutionsGrid = ({ list, nameSupported, register, registerText, path, linkTo }) => {
  const [hideGrid, setHideGrid] = useState(false)
  const [open, setOpen] = useState()
  console.log(linkTo)
  return (
    <>
      <InstitutionRow xs='2' sm='2' md='3' lg='4' xl='4' xxl='4'>
        {list.map(({ thumbnail, description, proBonoSupport, country, name }) => (
          <Institution
            thumbnail={thumbnail}
            description={description}
            proBonoSupport={proBonoSupport}
            country={country}
            hideGrid={hideGrid}
            handleHideGrid={setHideGrid}
            open={open}
            setOpen={setOpen}
            name={name}
            path={path}
            nameSupported={nameSupported}
          />
        ))}
        <InstitutionCol report2020={path} minWidth='50%' minWidthResp='100%'>
          <LastInstitutionContainer report2020={path} className='lastContainer'>
            <ContactSubtitleInstitutions
              report2020={path}
              style={{
                fontSize: '26px',
                fontWeight: 700,
                color: '#1c3651',
                paddingLeft: '0px',
                marginTop: '15px'
              }}>
              <InstitutionSpanSubtitle fs='14px'>
                <LinkTo to={{ pathname: linkTo }} target='_blank'>
                  {register}
                </LinkTo>
              </InstitutionSpanSubtitle>
              <InstitutionSpanSubtitle fs='14px' color='#1D3752'>
                {' '}
                {registerText}
              </InstitutionSpanSubtitle>
            </ContactSubtitleInstitutions>
          </LastInstitutionContainer>
        </InstitutionCol>
      </InstitutionRow>
    </>
  )
}

InstitutionsGrid.propTypes = {}

export default InstitutionsGrid
