import { Link } from 'react-router-dom'
import { Col, Row } from 'reactstrap'
import styled, { css } from 'styled-components'
import { classAnimateFadeIn } from '../../utils'
import { Element } from 'react-scroll'

export const ElementScroll = styled(Element)`
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
  @media (max-width: 640px) {
    ${props =>
      props.marginResp &&
      css`
        margin: ${props.marginResp};
      `}
  }
`

export const LinkTo = styled(Link)`
  text-decoration: underline;
  font-family: 'Montserrat', sans-serif;
  color: #33cc99;
`

export const InstitutionSpanSubtitle = styled.span`
  color: #33cc99;
  ${props =>
    props.color
      ? css`
          color: ${props.color};
        `
      : css`
          color: #33cc99;
        `}
  @media (max-width: 640px) {
    line-height: 0px;
    ${props =>
      props.fs &&
      css`
        font-size: ${props.fs};
      `}
  }
  /* Extra small devices (phones, 640px and down) */
  @media only screen and (max-width: 640px) {
    font-size: 14px;
    line-height: 15px;
  }
`

export const Container = styled.section`
  ${props =>
    props.bgColor &&
    css`
      background: ${props.bgColor};
    `}
  width: 100%;
  ${props =>
    props.padding &&
    css`
      padding: ${props.padding};
    `}
  ${props =>
    props.bgImg &&
    css`
      background-image: url(${props.bgImg});
      width: 100%;
      height: 100%;
      background-size: 100% auto;
      background-position: center;
      padding-bottom: 129px;
      object-fit: cover;
      box-shadow: inset 0 0 0 1000px rgb(0 0 0 / 50%);
      background-size: cover;
    `}
`
export const ContainerBody = styled.section`
  ${props =>
    props.bgColor &&
    css`
      background: ${props.bgColor};
    `}
  width: 100%;
  ${props =>
    props.padding &&
    css`
      padding: ${props.padding};
    `}
  ${props =>
    props.bgImg &&
    css`
      background-image: url(${props.bgImg});
      width: 100%;
      /* height: 100%; */
      background-size: 100% auto;
      background-position: center;
      padding-bottom: 89px;
      object-fit: cover;
      background-size: cover;
      box-shadow: inset 0 0 0 1000px rgb(0 0 0 / 50%);
    `}
    /* Extra small devices (phones, 640px and down) */
  @media only screen and (max-width: 640px) {
    height: auto;
    ${props =>
      props.ods &&
      css`
        padding: 80px 0;
      `}
  }
`

export const Img = styled.img`
  width: auto;
  max-width: 50%;
  height: auto;
  object-fit: cover;
  margin-left: 50%;
  transform: translateX(-50%);
  /* Extra small devices (phones, 640px and down) */
  @media only screen and (max-width: 640px) {
    max-width: 90%;
    display: flex;
    justify-content: center;
    margin-left: 184px;
  }
`

export const RowAbout = styled(Row)`
  ${props =>
    props.bgImg &&
    css`
      background-image: url(${props.bgImg});
      width: 100%;
      height: 100%;
      background-size: 100% auto;
      background-position: center;
      object-fit: cover;
      box-shadow: inset 0 0 0 1000px rgb(0 0 0 / 50%);
      background-size: cover;
      /* width: 100%;
      height: auto; */
    `}
  margin-top: 50px;
  /* Extra small devices (phones, 640px and down) */
  @media only screen and (max-width: 640px) {
    margin-top: 10px;
  }
`
export const ColAbout = styled(Col)`
  /* Extra small devices (phones, 640px and down) */
  @media only screen and (max-width: 640px) {
    min-width: 85vw;
    padding-left: 10px;
    padding-right: 10px;
  }
`

export const TextContainer = styled.div`
  font-family: 'Baloo 2';
  margin-bottom: -50px;
  ${props =>
    props.textAlign &&
    css`
      text-align: ${props.textAlign};
    `}
  ${props =>
    props.about &&
    css`
      /* text-align: ${props.textAlign}; */
      margin-bottom: -190px;
    `}
    @media only screen and (max-width: 640px) {
    margin-bottom: -50px;
  }
`
export const TitleTeam = styled.h2.attrs({
  className: classAnimateFadeIn
})`
  font-family: 'Baloo 2';
  color: #1c3651;
  margin-top: -90px;
  font-weight: bold;
  ${props =>
    props.fontsz
      ? css`
          font-size: ${props.fontsz};
        `
      : css`
          font-size: 60px;
        `}
  ${props =>
    props.padding &&
    css`
      padding-top: ${props.padding};
    `}
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin} !important;
    `}
  ${props =>
    props.marginTop &&
    css`
      margin: ${props.marginTop} !important;
    `}
  ${props =>
    props.textAlign &&
    css`
      text-align: ${props.textAlign};
    `}
  ${props =>
    props.color &&
    css`
      color: ${props.color};
    `}

  /* Extra small devices (phones, 640px and down) */
  @media only screen and (max-width: 640px) {
    margin-top: 50px;
    margin: 80px 0px 15px 0px;
    font-size: 30px;
  }
`

export const Title = styled.h2.attrs({
  className: classAnimateFadeIn
})`
  color: #1c3651;
  font-weight: 700;
  margin-top: 50px;
  ${props =>
    props.fontsz
      ? css`
          font-size: ${props.fontsz};
        `
      : css`
          font-size: 60px;
        `}
  ${props =>
    props.fontFamily
      ? css`
          font-family: ${props.fontFamily};
        `
      : css`
          font-family: 'Baloo 2';
        `}
  ${props =>
    props.padding &&
    css`
      padding-top: ${props.padding};
    `}
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin} !important;
    `}
  ${props =>
    props.marginTop &&
    css`
      margin: ${props.marginTop} !important;
    `}
  ${props =>
    props.textAlign &&
    css`
      text-align: ${props.textAlign};
    `}
  ${props =>
    props.color &&
    css`
      color: ${props.color};
    `}
  ${props =>
    props.fweight &&
    css`
      font-weight: ${props.fweight};
    `}

  /* Extra small devices (phones, 640px and down) */
  @media only screen and (max-width: 640px) {
    margin: 20px 0px 15px 0px !important;
    ${props =>
      props.fontSmall
        ? css`
            font-size: ${props.fontSmall};
          `
        : css`
            font-size: 30px;
          `}
    ${props =>
      props.marginSmall &&
      css`
        margin: ${props.marginSmall};
      `}
      ${props =>
      props.paddingResp &&
      css`
        padding: ${props.paddingResp};
      `}
  }
`

export const TitleMetricsMobile = styled.h2.attrs({
  className: classAnimateFadeIn
})`
  font-family: 'Baloo 2';
  color: #1c3651;
  margin-top: 50px;
  font-weight: 700;
  ${props =>
    props.fontsz
      ? css`
          font-size: ${props.fontsz};
        `
      : css`
          font-size: 60px;
        `}
  ${props =>
    props.padding &&
    css`
      padding-top: ${props.padding};
    `}
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin} !important;
    `}
  ${props =>
    props.marginTop &&
    css`
      margin: ${props.marginTop} !important;
    `}
  ${props =>
    props.textAlign &&
    css`
      text-align: ${props.textAlign};
    `}
  ${props =>
    props.color &&
    css`
      color: ${props.color};
    `}

  /* Extra small devices (phones, 640px and down) */
  @media only screen and (max-width: 640px) {
    margin: 50px 0 0 0 !important;
    text-align: center;
    font-size: 30px;
    color: white;
  }
`

export const TitleVolunteers = styled.h2.attrs({
  className: classAnimateFadeIn
})`
  font-family: 'Baloo 2';
  color: #1c3651;
  margin-top: 50px;
  font-weight: 700;
  ${props =>
    props.fontsz
      ? css`
          font-size: ${props.fontsz};
        `
      : css`
          font-size: 60px;
        `}
  ${props =>
    props.padding &&
    css`
      padding-top: ${props.padding};
    `}
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin} !important;
    `}
  ${props =>
    props.marginTop &&
    css`
      margin: ${props.marginTop} !important;
    `}
  ${props =>
    props.textAlign &&
    css`
      text-align: ${props.textAlign};
    `}
  ${props =>
    props.color &&
    css`
      color: ${props.color};
    `}

  /* Extra small devices (phones, 640px and down) */
  @media only screen and (max-width: 640px) {
    margin: 20px 0px 0px 20px !important;
    font-size: 32px;
    text-align: center;
  }
`

export const TitleMetricsMobileYear = styled.h2.attrs({
  className: classAnimateFadeIn
})`
  font-family: 'Baloo Tammudu 2';
  color: #1c3651;
  margin-top: 50px;
  font-weight: 700;
  ${props =>
    props.fontsz
      ? css`
          font-size: ${props.fontsz};
        `
      : css`
          font-size: 60px;
        `}
  ${props =>
    props.padding &&
    css`
      padding-top: ${props.padding};
    `}
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin} !important;
    `}
  ${props =>
    props.marginTop &&
    css`
      margin: ${props.marginTop} !important;
    `}
  ${props =>
    props.textAlign &&
    css`
      text-align: ${props.textAlign};
    `}
  ${props =>
    props.color &&
    css`
      color: ${props.color};
    `}

  /* Extra small devices (phones, 640px and down) */
  @media only screen and (max-width: 640px) {
    margin: -4px 0px 0 0px !important;
    font-size: 30px;
    text-align: center;
    ${props =>
      props.marginResp
        ? css`
            margin: ${props.marginResp} !important;
          `
        : css`
            margin: 20px 0px 15px 0px;
          `}
    ${props =>
      props.padding &&
      css`
        padding: ${props.padding};
      `}
    ${props =>
      props.fontszResp &&
      css`
        font-size: ${props.fontszResp};
      `}
  }
`

export const Underline = styled.div`
  ${props =>
    props.margin &&
    css`
      margin-left: ${props.margin};
    `}
  ${props =>
    props.marginBottom &&
    css`
      margin-bottom: ${props.marginBottom} !important;
    `}
  ${props =>
    props.allMargin &&
    css`
      margin: ${props.allMargin} !important;
    `}
  ${props =>
    props.transform &&
    css`
      transform: translateX(${props.transform});
    `}
  ${props =>
    props.color
      ? css`
          background-color: ${props.color};
        `
      : css`
          background-color: #33cc99;
        `}
  width: 5%;
  height: 5px;
  margin-top: -35px;
  @media only screen and (max-width: 640px) {
    margin-top: -16px;
    ${props =>
      props.marginResp &&
      css`
        margin: ${props.marginResp};
      `}
    ${props =>
      props.about &&
      css`
        margin-top: -16px;
      `}
  }
`

export const UnderlineMetrics = styled.div`
  ${props =>
    props.margin &&
    css`
      margin-left: ${props.margin};
    `}
  ${props =>
    props.allMargin &&
    css`
      margin: ${props.allMargin} !important;
    `}
  ${props =>
    props.transform &&
    css`
      transform: translateX(${props.transform});
    `}
  ${props =>
    props.color
      ? css`
          background-color: ${props.color};
        `
      : css`
          background-color: #33cc99;
        `}
  width: 5%;
  height: 5px;
  margin-top: -10px;
  /* Extra small devices (phones, 640px and down) */
  @media only screen and (max-width: 640px) {
    display: none;
  }
`

export const Subtitle = styled.h3`
  font-family: 'Baloo 2';
  font-weight: 700;
  ${props =>
    props.fontsz &&
    css`
      font-size: ${props.fontsz}px;
      line-height: ${props.fontsz}px;
    `}
  ${props =>
    props.marginBottom &&
    css`
      margin-bottom: ${props.marginBottom};
    `}
  ${props =>
    props.color &&
    css`
      color: ${props.color};
    `}
    /* Extra small devices (phones, 640px and down) */
  @media only screen and (max-width: 640px) {
    text-align: center;
    font-size: 21px;
    line-height: 17px;
    margin-bottom: 7px;
    ${props =>
      props.about &&
      css`
        margin-top: 60px;
      `}
  }
`

export const SubtitleOrange = styled.h3`
  font-family: 'Baloo 2';
  font-weight: 700;
  ${props =>
    props.fontsz &&
    css`
      font-size: ${props.fontsz}px;
    `}
  ${props =>
    props.color &&
    css`
      color: ${props.color};
    `}
    /* Extra small devices (phones, 640px and down) */
  @media only screen and (max-width: 640px) {
    text-align: center;
    font-size: 22px;
    line-height: 17px;
    ${props =>
      props.about &&
      css`
        margin-top: 0px;
      `}
  }
`

export const Ol = styled.ol`
  font-size: 15px;
  margin-top: -16px;
  @media only screen and (max-width: 640px) {
    margin-top: -16px;
  }
`

export const Li = styled.li`
  font-family: 'Montserrat', sans-serif;
  color: white;
  font-size: 25px;
  line-height: 25px;
  @media only screen and (max-width: 640px) {
    font-size: 18px;
  }
`
export const Ul = styled.ul`
  font-family: 'Montserrat', sans-serif;
  color: white;
  margin-left: 10px;
  margin-top: 12px;
  /* Extra small devices (phones, 640px and down) */
  @media only screen and (max-width: 640px) {
    margin-left: 10px;
    margin-top: 2px;
  }
`

export const Paragraph = styled.p`
  color: '#1c3651';
  ${props =>
    props.margin &&
    css`
      ${props.margin};
    `}
  ${props =>
    props.color &&
    css`
      color: ${props.color};
    `}
  ${props =>
    props.fweight &&
    css`
      font-weight: ${props.fweight};
    `}
  ${props =>
    props.lheight &&
    css`
      line-height: ${props.lheight};
    `}
  ${props =>
    props.fontFamily
      ? css`
          font-family: ${props.fontFamily};
        `
      : css`
          font-family: 'Montserrat', sans-serif;
        `}
  ${props =>
    props.fonts &&
    css`
      font-size: ${props.fonts};
    `}
  ${props =>
    props.textAlign &&
    css`
      text-align: ${props.textAlign};
    `}
  ${props =>
    props.padding &&
    css`
      padding: ${props.padding};
    `}

    /* Extra small devices (phones, 640px and down) */
  @media only screen and (max-width: 640px) {
    font-size: 19px;
    line-height: 19px;
    padding: 10px 0;
    margin-bottom: 10px;
    ${props =>
      props.lheightResp &&
      css`
        line-height: ${props.lheightResp} !important;
      `}
    ${props =>
      props.paddingResp &&
      css`
        padding: ${props.paddingResp} !important;
      `}
    ${props =>
      props.respFs &&
      css`
        font-size: ${props.respFs};
        padding: 0;
        line-height: 13px;
      `}
  }
  @media only screen and (max-width: 1140px) {
    font-size: 15px;
    line-height: 15px;
    padding-bottom: 50px;
  }
`

export const ParagraphODS = styled.p`
  font-family: 'Montserrat', sans-serif;
  color: '#1c3651';
  ${props =>
    props.color &&
    css`
      color: ${props.color};
    `}
  ${props =>
    props.fonts &&
    css`
      font-size: ${props.fonts};
    `}
  ${props =>
    props.textAlign &&
    css`
      text-align: ${props.textAlign};
    `}
  ${props =>
    props.padding &&
    css`
      padding: ${props.padding};
    `}

    /* Extra small devices (phones, 640px and down) */
  @media only screen and (max-width: 640px) {
    font-size: 19px;
    line-height: 22px;
    padding: 107px 0;
    margin: 45px 0 90px 0;
    ${props =>
      props.respFs &&
      css`
        font-size: ${props.respFs};
        padding: 0;
        line-height: 13px;
      `}
    @media only screen and (max-width: 640px) {
      padding: 50px 0 10px 0;
      line-height: 23px;
    }
  }
`
export const BackLink = styled(Link)`
  position: relative;
  left: 735px;
  top: -465px;

  @media only screen and (max-width: 640px) {
    position: static;
    padding: 40px 138px 0 138px
  }
`


export const BackArrow = styled.img`
    width: 30%;

@media only screen and (max-width: 640px){
    width: 100%;
  }
`

export const RowCenterTeam = styled(Row)`
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
  margin-bottom: 60px;

  @media only screen and (max-width: 640px) {
    margin-left: 2px;
    margin-bottom: 20px;
  }
`