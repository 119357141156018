import React from 'react'
import { Title, Li, Ul, Ol, Paragraph } from '../StyledComponents/styled'

const ScopeCountries = ({ title, data }) => {
  console.log(data)
  return (
    <>
      <Title
        color='#FF8300'
        fontFamily='Montserrat'
        fontsz='33px'
        fontSmall='20px'
        fweight='900'>
        {title}
      </Title>
      <Ol>
        {data &&
          data.map(c => {
            return (
              <>
                <Li>{c.scopeProjectState}</Li>
                <Ul>
                  {c.scopeProjectCities &&
                    c.scopeProjectCities.map(c => {
                      return <Li>&nbsp;&nbsp; -&nbsp; {c.scopeProjectCity}</Li>
                    })}
                </Ul>
              </>
            )
          })}
      </Ol>
    </>
  )
}

export default ScopeCountries
