import React from 'react'
import { Col, Row } from 'reactstrap'
import styled, { css } from 'styled-components'

export const FooterDark = styled.footer`
  margin-top: auto;
  text-align: center;
  background: #1c3651;
  display: grid;
  align-items: center;
  justify-items: center;
`
export const BlueContainer = styled.section.attrs({ class: 'container' })`
  background: #1c3651;
  width: 100%;
  padding: 0 23px;
  @media only screen and (max-width: 640px) {
    padding: 0;
  }
`
export const WhiteContainer = styled.section`
  background: #ededed;
  padding: 5% 30%;
`
export const RowContact = styled(Row)`
  padding: 0% 3% 5% 3%;
  margin-bottom: 0px;
  color: #fff;
  text-align: left;

  @media (max-width: 768px) {
    padding: 0% 9% 5% 7%;
  }
`

export const SeparatorGreen = styled.div`
  border-top: 4px solid #33cc99;
  width: 70px;
  margin-left: 16px;
  margin-bottom: 37px;
  margin-top: -20px;
  /* Extra small devices (phones, 640px and down) */
  @media only screen and (max-width: 640px) {
    text-align: center;
    width: 30px;
    margin-left: calc(50% - 15px);
    margin-top: -10px;
  }
`

export const RowTitle = styled(Row)`
  padding: 5% 0% 0% 4%;
  font-family: 'Montserrat';
  color: #fff;
  text-align: left;
  @media (max-width: 768px) {
    padding: 50px 15% 20px 13%;
  }
`
export const ContactTitle = styled.p`
  font-size: 26px;
  line-height: 30px;
  font-family: 'Montserrat';
  margin-bottom: 10px;
  font-weight: bold;
  @media only screen and (max-width: 640px) {
    font-size: 22px;
    line-height: 23px;
  }
`
export const ContactSubtitle = styled.p`
  font-weight: regular;
  font-family: 'Montserrat';
  font-size: 24px;
  line-height: 30px;
  /* Extra small devices (phones, 640px and down) */
  @media only screen and (max-width: 640px) {
    font-size: 20px;
    min-width: 89vw;
    /* padding-left: 10px; */
    padding-right: 10px;
    padding-top: 5px;
    line-height: 21px;
  }
`
export const ContactSubtitleInstitutions = styled.p`
  font-weight: regular;
  font-family: 'Montserrat';
  font-size: 24px;
  line-height: 30px;
  ${props =>
    props.report2020 === '2020' &&
    css`
      font-size: 16px !important;
    `}
  /* Extra small devices (phones, 640px and down) */
  @media only screen and (max-width: 640px) {
    font-size: 20px;
    min-width: 89vw;
    /* padding-left: 10px; */
    padding-right: 10px;
    padding-top: 5px;
    line-height: 21px;
  }
`
export const ContactSubtitleGreen = styled.p`
  font-weight: regular;
  font-family: 'Montserrat';
  font-size: 24px;
  color: #33cc99;
  line-height: 30px;
  /* Extra small devices (phones, 640px and down) */
  @media only screen and (max-width: 640px) {
    line-height: 21px;
    font-size: 20px;
  }
`
export const ContactTitleSection = styled.p`
  margin-bottom: 0px;
  font-size: 60px;
  font-family: 'Baloo 2';
  font-weight: bold;
  /* Extra small devices (phones, 640px and down) */
  @media only screen and (max-width: 640px) {
    font-size: 30px;
    text-align: center;
    font-weight: regular;
  }
`

export const ColContact = styled(Col)`
  padding: 1% 2% 2% 2%;
  min-width: 364px;
  @media screen and (max-width: 768px) {
    padding: 3% 2% 8% 2%;
  }
`
export const ContactSpanSubtitle = styled.span`
  color: #33cc99;
  ${props =>
    props.color
      ? css`
          color: ${props.color};
        `
      : css`
          color: #33cc99;
        `}
  @media (max-width: 640px) {
    line-height: 0px;
    ${props =>
      props.fs &&
      css`
        font-size: ${props.fs};
      `}
  }
  /* Extra small devices (phones, 640px and down) */
  @media only screen and (max-width: 640px) {
    font-size: 20px;
    line-height: 30px;
  }
`
export const MailtoFooter = styled.a`
  font-family: 'Montserrat', sans-serif;
  color: #33cc99;
`
