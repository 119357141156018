import { Col, Row } from 'reactstrap'
import styled from 'styled-components'

export const TeamCardRo = styled.div`
  width: 327px;
  height: 150px;
  background-color: #fff;
  display: inline-table;
  /* margin: 20px 0px; */
  @media (max-width: 640px) {
    height: 100px;
    margin-top: -50px;
  }
`
export const TeamInfo = styled.div`
  height: 50px;
  width: 200px;
  margin-top: -50px;
  background: #33cc99d4;
  padding: 10px;
  color: #1c3651;
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  display: inherit;
  @media (max-width: 640px) {
    width: 140px;
    height: 40px;
    font-size: 11px;
    margin-top: -40px;
  }
`

export const TeamLabelInfoBold = styled.label`
  font-family: 'Montserrat';
  font-weight: 800;
  margin-top: -2px;
  width: 100%;
`
export const TeamLabelInfoText = styled.label`
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 13px;
  margin-top: -5px;
  width: 100%;
  @media (max-width: 640px) {
    margin-top: -5px;
    font-size: 9px;
  }
`

export const TeamListContainer = styled.div`
  /* height: 30px;
  width: 170px;
  margin-top: -30px;
  background: #33cc9987; */
  display: grid;
  padding-bottom: 50px;
  padding-top: 40px;
  justify-content: center;
  @media (max-width: 640px) {
    padding-bottom: 0px;
  }
`

export const TeamImage = styled.img`
  width: 200px;
  height: 200px;
  object-fit: cover;
  @media (max-width: 640px) {
    width: 140px;
    height: 140px;
  }
`

export const ImageRo = styled.img`
  width: 100%;
  height: 190px;
  object-fit: cover;
  object-position: bottom;
  cursor: pointer;
  @media (max-width: 640px) {
    width: 135px;
    height: 165px;
    margin-left: -45px;
  }
`
export const RowCardRo = styled(Row)`
  padding: 0px;
  margin: 30px 0px 30px 6px;
  width: 100%;
  justify-content: center;
  background-color: #ededed;
  @media (max-width: 640px) {
    margin: 30px 0px 0px 6px;
  }
`
export const ColTeam = styled(Col)`
  @media (max-width: 640px) {
    width: 50%;
  }
`

export const ColCardRo = styled(Col)`
  padding: 0px;
  margin: 0px;
  display: grid;
  align-items: center;
  justify-items: center;
`
export const TeamLabelRo = styled.label`
  font-size: 24px;
  font-weight: bold;
  color: #1c3651;
  font-family: 'Montserrat';
  @media (max-width: 640px) {
    font-size: 14px;
    margin-left: -45px;
  }
`

export const PRo = styled.p`
  font-family: 'Montserrat';
  font-size: 15px;
  margin: 22px 0 -8px 0;
  @media (max-width: 640px) {
    margin: 0px 0px 0px -10px;
    font-size: 12px;
  }
`
export const PRoRole = styled.p`
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 15px;
  @media (max-width: 640px) {
    margin: 0px 0px 0px -10px;
    font-size: 12px;
  }
`
