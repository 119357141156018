import React from 'react'
import PropTypes from 'prop-types'
import { ContainerHome } from '../Pages/Home'
import AlliancesTabs from './AlliancesTabs'
import { TextContainer, Title, Underline } from '../StyledComponents/styled'

const Alliances = ({ data }) => {
  return (
    <ContainerHome style={{ padding: '49px 0% 50px 0%', maxWidth: '80%', marginBottom: '40px' }}>
      <TextContainer textAlign='center'>
        <Title>{data?.alliancesTitle}</Title>
        <Underline marginResp='-16px 50%' marginBottom='50px' margin='50%' transform='-50%' />
      </TextContainer>
      {data?.alliancesInterns ? (
        <AlliancesTabs
          alliancesInterns={data?.alliancesInterns}
          alliancesStrategics={data?.alliancesStrategics}
        />
      ) : (
        <AlliancesTabs alliancesStrategics={data?.alliancesStrategics} />
      )}
    </ContainerHome>
  )
}

Alliances.propTypes = {}

export default Alliances
