import styled, { css } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const CircleContainer = styled.div`
  background-color: #1c3651;
  border-radius: 100%;
  width: 76px;
  height: 76px;
  text-align: center;
  margin-bottom: 10px;
`

export const Icon = styled(FontAwesomeIcon)`
  color: #33cc99;
  font-size: 30px;
  margin-top: 50%;
  transform: translateY(-50%);
`
export const IconObjetive = styled.img`
  color: #33cc99;
  width: 40px;
  margin-top: 50%;
  transform: translateY(-50%);
`

export const GrayContainer = styled.div`
  width: 100%;
  height: auto;
  background-color: #ededed;
  border-radius: 32px;
  padding: 20px;

  /* Extra small devices (phones, 640px and down) */
  @media only screen and (max-width: 640px) {
    margin-top: 0px;
    width: 110%;
    margin-left: -10px;
    margin-bottom: 20px;
  }
`
export const Image = styled.img`
  position: absolute;
  width: 60%;
  z-index: -1;
  height: 100%;
  right: 0;
`

export const ImageAbout = styled.section`
  margin-bottom: -5%;
  ${props =>
    props.bgImg &&
    css`
      background: url(${props.bgImg}), #fdfdfd;
      width: 100%;
      /* height: 100%; */
      background-size: 70% !important;
      height: 140%;
      background-position: right;
      padding-bottom: 89px;
      object-fit: cover;
      background-size: cover;
      background-repeat: no-repeat;
    `}
  /* Extra small devices (phones, 640px and down) */
@media only screen and (max-width: 640px) {
    background: #fdfdfd;
    padding: 0 5px;
  }
`
export const SubtitleObjective = styled.h3`
  font-family: 'Baloo 2';
  font-weight: 700;
  ${props =>
    props.fontsz &&
    css`
      font-size: ${props.fontsz}px;
    `}
  ${props =>
    props.color &&
    css`
      color: ${props.color};
    `}
    /* Extra small devices (phones, 640px and down) */
  @media only screen and (max-width: 640px) {
    text-align: left;
    font-size: 25px;
    line-height: 17px;
    margin-bottom: 0;
    margin-top: 25px;
  }
`
