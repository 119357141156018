import React from 'react'
import { Container } from 'reactstrap'
import logo from '../../images/logos/logoprobono.webp'
import parse from 'html-react-parser'
import { LinkTo } from '../StyledComponents/styled'
import Carbonbadge from 'react-carbonbadge'
import {
  BlueContainer,
  ColContact,
  ContactSpanSubtitle,
  ContactSubtitle,
  ContactSubtitleGreen,
  ContactTitle,
  ContactTitleSection,
  FooterDark,
  MailtoFooter,
  RowContact,
  RowTitle,
  SeparatorGreen,
  WhiteContainer
} from './FooterStyled'



const Footer = ({ contact }) => {
  return (
    <FooterDark>
      <WhiteContainer>
        <Container>
          <img src={logo} alt='Pro Bono' className='img-fluid' />
        </Container>
      </WhiteContainer>
      <BlueContainer>
        <RowTitle>
          <ContactTitleSection>{contact?.contactTitle}</ContactTitleSection>
          <SeparatorGreen />
        </RowTitle>
        <RowContact>
          <ColContact>
            <ContactTitle>{parse(contact?.contactDoubts)}</ContactTitle>
            <ContactSubtitleGreen>
              <MailtoFooter href='mailto:probono@softtek.com?Subject=Dudas'>
                probono@softtek.com
              </MailtoFooter>
              <br />
              <MailtoFooter href='mailto:mariar.castillo@softtek.com?Subject=Dudas'>
                mariar.castillo@softtek.com
              </MailtoFooter>
            </ContactSubtitleGreen>
          </ColContact>
          <ColContact>
            <ContactTitle>{parse(contact?.contactVolunteerTitle)}</ContactTitle>
            <ContactSubtitle>
              <LinkTo
                target='_blank'
                to={{
                  pathname:
                    'https://forms.office.com/Pages/ResponsePage.aspx?id=melOhjjFoES93QRJ3mLfbe_x7lbJjvNLvRuNTzczcUdUMFRJR1VZMjlYSlZVUERYMzNOUDBRMlpYQy4u&wdLOR=cC22935DE-6F61-4226-8BA5-A49E7AA53287'
                }}>
                <ContactSpanSubtitle>{contact?.contactVolunteerClic}</ContactSpanSubtitle>
              </LinkTo>
              {contact?.contactVolunteerText}
            </ContactSubtitle>
          </ColContact>
          <ColContact>
            <ContactTitle>{contact?.contactACTitle}</ContactTitle>
            <ContactSubtitle>
              <LinkTo
                target='_blank'
                to={{
                  pathname:
                    'https://forms.office.com/Pages/ResponsePage.aspx?id=melOhjjFoES93QRJ3mLfbe_x7lbJjvNLvRuNTzczcUdUOTZBUUlRTEhVSTNGVTNRNVUwSEU0U0wyNC4u&wdLOR=cF4F73CD6-717D-4A62-9791-3EC72893E01D'
                }}>
                <ContactSpanSubtitle>{contact?.contactACClic}</ContactSpanSubtitle>
              </LinkTo>
              {contact?.contactACText}
            </ContactSubtitle>
          </ColContact>
        </RowContact>
        <RowContact>
          <ColContact>
            <Carbonbadge darkMode={true} />
          </ColContact>
        </RowContact>
      </BlueContainer>
    </FooterDark>
  )
}

export default Footer
