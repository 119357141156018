import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-scroll'
import { LabelLink, LabelLinkResponsive } from './NavigationDarkStyled'
import { NavDropdown } from 'react-bootstrap'

const NavLinkScroll = ({ toLink, nameLink, responsive, onclick }) => {
  return (
    <>
      {responsive ? (
        <Link
          activeClass='active'
          to={toLink}
          onClick={onclick}
          spy={true}
          smooth={true}
          offset={-60}
          duration={500}>
          <LabelLinkResponsive>{'' + nameLink}</LabelLinkResponsive>
        </Link>
      ) : (
        <NavDropdown.Item href='#'>
          <Link
            activeClass='active'
            to={toLink}
            spy={true}
            smooth={true}
            offset={0}
            duration={500}>
            <LabelLink>{'' + nameLink}</LabelLink>
          </Link>
        </NavDropdown.Item>
      )}
    </>
  )
}

NavLinkScroll.propTypes = {}

export default NavLinkScroll
