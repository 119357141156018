import React from 'react'
import PropTypes from 'prop-types'
import {
  TeamImage,
  TeamInfo,
  TeamLabelInfoBold,
  TeamLabelInfoText,
  TeamListContainer,
  ColTeam
} from './TeamStyled'

const TeamList = ({ image, name, role }) => {
  return (
    <ColTeam>
      <TeamListContainer>
        <TeamImage src={image} />
        <TeamInfo>
          <TeamLabelInfoBold>{name}</TeamLabelInfoBold>
          <TeamLabelInfoText>{role}</TeamLabelInfoText>{' '}
        </TeamInfo>
      </TeamListContainer>
    </ColTeam>
  )
}

TeamList.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired
}

TeamList.defaultProps = {
  image: require('./../../images/Equipo/Equipo/jeniffer.webp'),
  name: 'Jeniffer Castañeda',
  role: 'Líder de Análisis'
}

export default TeamList
