import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import {
  NavbarContainer,
  LeftContainer,
  RightContainer,
  NavbarExtendedContainer,
  NavbarInnerContainer,
  NavbarLinkContainer,
  NavbarLinkLogo,
  Logo,
  OpenLinksButton,
  NavbarLinkExtended,
  MenuNavDropdown,
  LabelLink,
  LabelLinkTitle,
  LinkTitleMobile,
  NavbarLinkExtendedMin,
  IconNavBar,
  LabelLinkResponsive
} from './NavigationDarkStyled'
import logo from './../../images/logos/logo-softtek.webp'
import { Image, Nav, NavDropdown } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import NavLinkScroll from './NavLinkScroll'
import parse from 'html-react-parser'


const NavbarDark = ({ data }) => {
  const { pathname } = useLocation()
  const pathTeam = pathname.split('/')[1]

  const {
    navBar,
    aboutUs,
    supportedInstitutions,
    alliances,
    scopeProjects,
    metrics,
    civilAssociations,
    volunteers
  } = data
  const [extendNavbar, setExtendNavbar] = useState(false)
  const icon = (
    <FontAwesomeIcon icon={faBars} style={{ marginLeft: '35px', marginRight: '35px' }} />
  )
  return (
    <NavbarContainer id='navbar' extendNavbar={extendNavbar}>
      <NavbarInnerContainer>
        <LeftContainer>
          <NavbarLinkLogo to='/'>
            <Logo src={logo}></Logo>
          </NavbarLinkLogo>
        </LeftContainer>
        <RightContainer>
          <NavbarLinkContainer>
            <Nav.Link href={navBar?.navBarLinkTeam}>
              <LabelLink>{navBar?.navBarOurTeam}</LabelLink>
            </Nav.Link>

            {pathTeam != 'team' ?
              <MenuNavDropdown title={navBar?.navBarSections} id='collasible-nav-dropdown'>
                <NavLinkScroll toLink={'aboutScroll'} nameLink={aboutUs?.aboutUsTitle} />
                <NavLinkScroll toLink={'odsScroll'} nameLink={'ODS'} />
                <NavLinkScroll
                  toLink={'InstitucionesScroll'}
                  nameLink={supportedInstitutions?.supportedInstitutionsTitle}
                />
                <NavLinkScroll toLink={'AlliancesScroll'} nameLink={alliances?.alliancesTitle} />
                <NavLinkScroll
                  toLink={'ScopeScroll'}
                  nameLink={parse(scopeProjects?.scopeProjectsTitleSection)}
                />
                <NavLinkScroll toLink={'MetricsScroll'} nameLink={metrics?.metricsTitle} />
                <NavLinkScroll
                  toLink={'AsosiationsScroll'}
                  nameLink={civilAssociations?.civilAssociationsTitle}
                />
                <NavLinkScroll
                  toLink={'VolunteersScroll'}
                  nameLink={volunteers?.volunteersSection}
                />
              </MenuNavDropdown> : ''
            }
            <MenuNavDropdown
              title={
                <Image
                  style={{ height: '30px', margin: '-4px 3px 0 0' }}
                  src={require('./softteklanguage.webp')}
                />
              }
              id='collasible-nav-dropdown'>
              <NavDropdown.Item href='/2021/es'>{navBar?.navBarLinkEs}</NavDropdown.Item>
              <NavDropdown.Item href='/2021/en'>{navBar?.navBarLinkEn}</NavDropdown.Item>
              <NavDropdown.Item href='/2021/pt'>{navBar?.navBarLinkPt}</NavDropdown.Item>
            </MenuNavDropdown>

            <OpenLinksButton
              onClick={() => {
                setExtendNavbar(curr => !curr)
              }}>
              {extendNavbar ? <>&#10005;</> : <> &#8801;</>}
            </OpenLinksButton>
          </NavbarLinkContainer>
        </RightContainer>
      </NavbarInnerContainer>
      {extendNavbar && (
        <NavbarExtendedContainer>
          <NavbarLinkExtended style={{ marginBottom: '20px' }} href={navBar?.navBarLinkTeam}>
            {navBar.navBarOurTeam}
          </NavbarLinkExtended>

          <LinkTitleMobile>{navBar.navBarHistorics}</LinkTitleMobile>
          {/* <LinkTitleMobile>{navBar?.navBarHistoricsYearSecond}</LinkTitleMobile> */}
          <NavbarLinkExtendedMin href='/2021/es'>{navBar?.navBarLinkEs}</NavbarLinkExtendedMin>
          <NavbarLinkExtendedMin href='/2021/en'>{navBar?.navBarLinkEn}</NavbarLinkExtendedMin>
          <NavbarLinkExtendedMin href='/2021/pt'>{navBar?.navBarLinkPt}</NavbarLinkExtendedMin>
          <br />
          {pathTeam !== 'team' ?
          <>
          <LinkTitleMobile>{navBar.navBarSections}</LinkTitleMobile>
          <NavLinkScroll
            responsive
            toLink={'aboutScroll'}
            nameLink={aboutUs?.aboutUsTitle}
            onclick={() => {
              setExtendNavbar(false)
            }}
          />
          <NavLinkScroll
            responsive
            toLink={'InstitucionesScroll'}
            nameLink={supportedInstitutions?.supportedInstitutionsTitle}
            onclick={() => {
              setExtendNavbar(false)
            }}
          />
          <NavLinkScroll
            onclick={() => {
              setExtendNavbar(false)
            }}
            responsive
            toLink={'AlliancesScroll'}
            nameLink={alliances?.alliancesTitle}
          />
          <NavLinkScroll
            onclick={() => {
              setExtendNavbar(false)
            }}
            responsive
            toLink={'ScopeScroll'}
            nameLink={scopeProjects?.scopeProjectsTitleSection}
          />
          <NavLinkScroll
            onclick={() => {
              setExtendNavbar(false)
            }}
            responsive
            toLink={'MetricsScroll'}
            nameLink={metrics?.metricsTitle}
          />
          <NavLinkScroll
            onclick={() => {
              setExtendNavbar(false)
            }}
            responsive
            toLink={'AsosiationsScroll'}
            nameLink={civilAssociations?.civilAssociationsTitle}
          />
          <NavLinkScroll
            onclick={() => {
              setExtendNavbar(false)
            }}
            responsive
            toLink={'VolunteersScroll'}
            nameLink={volunteers?.volunteersSection}
          />

          </>
          : ''      }
        </NavbarExtendedContainer>
      )}
    </NavbarContainer>
  )
}

export default NavbarDark
