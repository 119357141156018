import React from 'react'
import logo from './../../images/logos/probonologo.webp'
import { SplashScreenHalfSpinnerText, SplashScreenSpinner, SplashScreenSpinnerImage, SplashScreenSpinnerText } from './SplashScreenStyledComponents'

export const SplashScreen = () => {
  return (
    <SplashScreenSpinner>
      <SplashScreenSpinnerImage src={logo}  alt='logo' />
      <SplashScreenSpinnerText>Cargando</SplashScreenSpinnerText>
      <SplashScreenHalfSpinnerText ></SplashScreenHalfSpinnerText>
    </SplashScreenSpinner>
  )
}
