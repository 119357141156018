import React from 'react'
import { Title, Paragraph, ContainerBody } from '../StyledComponents/styled'
import { Container, Row, Col } from 'reactstrap'
import { ScopeImg } from './ScopeStyled'
import ScopeCountries from './ScopeCountries'
import parse from 'html-react-parser'

const ScopeProjects = ({ data }) => {
  return (
    <ContainerBody bgColor='#001830' style={{ paddingBottom: '60px', marginTop: '-10px' }}>
      <Container style={{ maxWidth: '93%' }}>
        <Title
          paddingResp='40px 50px 0 50px'
          padding='40px'
          fontSmall='27px'
          textAlign='center'
          color='#33CC99'>
          {parse(data?.scopeProjectsTitle)}
        </Title>
        <Paragraph
          paddingResp='0'
          lheightResp='25px'
          fonts='28px'
          padding='20px'
          color='white'>
          {data?.scopeProjectsDescription}
        </Paragraph>
        <Row>
          <Col lg='6' style={{ paddingLeft: '30px' }}>
            <ScopeCountries
              title={
                data?.scopeProjectsCountries?.scopeProjectsEurope?.scopeProjectsEuropeName
              }
              data={data?.scopeProjectsCountries?.scopeProjectsEurope?.scopeProjectsEuropeList}
            />
            <ScopeCountries
              title={
                data?.scopeProjectsCountries?.scopeProjectsNorthAmerica
                  ?.scopeProjectsNorthAmericaName
              }
              data={
                data?.scopeProjectsCountries?.scopeProjectsNorthAmerica
                  ?.scopeProjectsNorthAmericaList
              }
            />
            <ScopeCountries
              title={
                data?.scopeProjectsCountries?.scopeProjectsSouthAmerica
                  ?.scopeProjectsSouthAmericaName
              }
              data={
                data?.scopeProjectsCountries?.scopeProjectsSouthAmerica
                  ?.scopeProjectsSouthAmericaList
              }
            />
          </Col>
          <Col lg='6'>
            <ScopeImg src={data?.scopeProjectsWorldMapImage} alt='world-map' />
          </Col>
        </Row>
      </Container>
    </ContainerBody>
  )
}

export default ScopeProjects
