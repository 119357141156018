import React from 'react'
import parse from 'html-react-parser'
import {
  ColAbout,
  Paragraph,
  RowAbout,
  Subtitle,
  SubtitleOrange,
  TextContainer,
  Title,
  Underline
} from '../StyledComponents/styled'
import {
  CircleContainer,
  GrayContainer,
  IconObjetive,
  SubtitleObjective,
  ImageAbout
} from './AboutStyled'
import img from './../../images/14624.webp'
import objectiveIcon from './../../images/About/objetivo.webp'

import { ContainerHome } from '../Pages/Home'

function About({ aboutUs }) {
  return (
    <>
      <ImageAbout noShadow bgImg={img}>
        <TextContainer about style={{ background: 'transparent' }} textAlign='center'>
          <Title>{aboutUs?.aboutUsTitle}</Title>
          <Underline about margin='50%' transform='-50%' />
        </TextContainer>
        <ContainerHome style={{ background: 'transparent', paddingBottom: '90px' }}>
          <RowAbout>
            <ColAbout lg='6'>
              <Subtitle about marginBottom='-1px' fontsz='29' color='#1c3651'>
                {parse(aboutUs?.aboutUsSubtitle)}
              </Subtitle>
              <SubtitleOrange about fontsz='28' color='#ff8300'>
                {aboutUs?.aboutUsSubtitleColor}
              </SubtitleOrange>
              <Paragraph fonts='20px'>{aboutUs?.aboutUsText}</Paragraph>
              <GrayContainer>
                <CircleContainer>
                  <IconObjetive src={objectiveIcon}></IconObjetive>
                </CircleContainer>
                <SubtitleObjective fontsz='29' color='#33cc99'>
                  {aboutUs?.aboutUsObjective?.aboutUsObjectiveTitle}
                </SubtitleObjective>
                <Paragraph
                  paddingResp='0'
                  lheightResp='18px'
                  margin='margin-top: 10px'
                  lheight='26px'
                  fonts='20px'>
                  {aboutUs?.aboutUsObjective?.aboutUsObjectiveText}
                </Paragraph>
              </GrayContainer>
            </ColAbout>
          </RowAbout>
        </ContainerHome>
      </ImageAbout>
    </>
  )
}

export default About
