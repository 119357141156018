import React from 'react'
import CivilCard from './CivilCard'
import { Title, Underline } from './../StyledComponents/styled'
import { Row } from 'reactstrap'
import { CivilCol, CivilRow } from './CivilStyled'
import { CivilGroup } from './CivilStyled'
const CivilAssociations = ({ data }) => {
  return (
    <>
      <>
        <Row>
          <Title textAlign='center' paddingResp='0 80px' padding='0px 20px'>
            {data?.civilAssociationsTitle}
          </Title>
          <Underline margin='50%' marginResp='-3% 0 10% 50%' transform='-50%' />
        </Row>
        <CivilRow style={{ marginBottom: '15%', marginTop: '5%' }}>
          <CivilGroup>
            {data?.civilAssociationsCards.map(c => (
              <>
                <CivilCol>
                  <CivilCard
                    image={c.civilAssociationsCardsImage}
                    name={c.civilAssociationsCardsName}
                    subtitle={c.civilAssociationsCardsSubtitle}
                    description={c.civilAssociationsCardsDescription}
                    logo={c.civilAssociationsCardsLogo}
                  />
                </CivilCol>
              </>
            ))}
          </CivilGroup>
        </CivilRow>
      </>
    </>
  )
}

export default CivilAssociations
