import React, { useState } from 'react'
import { TabContent, TabPane, Nav, NavItem, NavLink, Container } from 'reactstrap'
import classnames from 'classnames'
import AlliancesStrategics from './AlliancesStrategics'
import AlliancesInterns from './AlliancesInterns'
import { NavLinkTab, NavLinkTabAlliances, NavTabs } from './AlliancesStyled'
const AlliancesTabs = ({ alliancesStrategics, alliancesInterns }) => {
  const [activeTab, setActiveTab] = useState('1')

  const toggle = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }
  return (
    <Container>
      <NavTabs tabs>
        <NavItem>
          <NavLinkTabAlliances
            className={classnames({ active: activeTab === '1' })}
            onClick={() => toggle('1')}>
            {alliancesStrategics?.alliancesStrategicsButton}
          </NavLinkTabAlliances>
        </NavItem>
        {alliancesInterns?.alliancesInternsItems && (
          <NavItem>
            <NavLinkTabAlliances
              className={classnames({ active: activeTab === '2' })}
              onClick={() => toggle('2')}>
              {alliancesInterns?.alliancesInternsButton}
            </NavLinkTabAlliances>
          </NavItem>
        )}
      </NavTabs>
      <TabContent activeTab={activeTab}>
        <TabPane tabId='1'>
          <AlliancesStrategics
            alliancesStrategics={alliancesStrategics?.alliancesStrategicsItems}
          />
        </TabPane>
        {alliancesInterns?.alliancesInternsItems && (
          <TabPane tabId='2'>
            <AlliancesInterns alliancesInterns={alliancesInterns?.alliancesInternsItems} />
          </TabPane>
        )}
      </TabContent>
    </Container>
  )
}

export default AlliancesTabs
