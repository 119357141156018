import React from 'react'
import { Paragraph, Title } from '../StyledComponents/styled'
import { CircleImg, CardImgBorder, CardContainer, HeaderContainer } from './CivilStyled'

const CivilCard = ({ name, image, logo, subtitle, description }) => {
  return (
    <>
      <CardImgBorder top width='100%' src={image} alt={name} />
      <CardContainer>
        <CircleImg src={logo} />
        <HeaderContainer>
          <Title
            fontsz='24px'
            fontSmall='20px'
            fontFamily='Montserrat'
            fweight='900'
            fontszResp='20px'
            color='#FF8300'
            marginResp='-1px 0px 15px 0px'>
            {name}
          </Title>
          <Paragraph
            fonts='16px'
            margin='margin-top:-13px'
            fontFamily='Montserrat, sans-serif'
            fweight='500'
            color='#1C3651'
            respFs='13px'>
            <strong>{subtitle}</strong>
          </Paragraph>
          <Paragraph fonts='18px' respFs='14px'>
            {description}
          </Paragraph>
        </HeaderContainer>
      </CardContainer>
    </>
  )
}

export default CivilCard
