import React from 'react'
import parse from 'html-react-parser'
import { Container } from 'reactstrap'
import { ContainerBody, Img, ParagraphODS, Title, Underline } from '../StyledComponents/styled'
import bgImg from './../../images/ODS/ODS2021.webp'
import styled, { css } from 'styled-components'
import { Row } from 'reactstrap'

export const OdsImg = styled.img`
  width: 100%;
  max-width: 70%;
  margin-left: 50%;
  transform: translateX(-50%);
  @media only screen and (max-width: 640px) {
    max-width: 80%;
  }
`

export const ImgContainer = styled(Row)`
  @media only screen and (max-width: 640px) {
  }
`

const ODS = ({ data }) => {
  return (
    <>
      <ContainerBody ods bgImg={bgImg}>
        <Container>
          <Title
            paddingResp='20px 0 0 0'
            padding='40px'
            color='#33CC99'
            textAlign='center'>
            {data?.odsTitle}
          </Title>
          <Underline margin='50%' transform='-50%' color='#ff8300' />
          <ParagraphODS fonts='30px' textAlign='center' padding='80px 100px' color='white'>
            {parse(data?.odsDescription || '')}
          </ParagraphODS>
          <ImgContainer>
            <OdsImg src={data?.odsImage} />
          </ImgContainer>
        </Container>
      </ContainerBody>
    </>
  )
}

export default ODS
