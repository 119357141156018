import React from 'react'
import Footer from './Footer/Footer'
import NavbarDark from './NavBar/NavbarDark'

const Layout = ({ children, data }) => {
  const html = document.getElementsByTagName('html')[0]
  const body = document.body
  const main = document.getElementById('root')
  html.classList.add('h-100')
  body.classList.add('h-100')
  main.classList.add('d-flex')
  main.classList.add('flex-column')
  main.classList.add('h-100')

  return (
    <>
      <NavbarDark data={data} />
      {children}
      <Footer contact={data.contact} />
    </>
  )
}

export default Layout
