import React, { useEffect, useState } from 'react'
import { Container } from 'reactstrap'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import { Element } from 'react-scroll'

import About from '../About/About'
import Alliances from '../Alliances/Alliances'
import Banner from '../Banner/Banner'
import CivilAssociations from '../CivilAssociations/CivilAssociations'
import Layout from './../Layout'
import Metrics from '../Metrics/Metrics'
import ScopeProjects from '../Scope/ScopeOfProjects'
import SupportedInstitutions from '../SupportedInstitutions/SupportedInstitutions'
import ODS from '../ODS/ODS2021'
import Volunteers from '../Volunteers/Volunteers'
import { SplashScreen } from '../SplashScreen/SplashScreen'
import { isEmpty } from '../../utils'
import { ElementScroll } from '../StyledComponents/styled'

export const ContainerHome = styled(Container)`
  background-color: #fff;
  z-index: 3;
  padding-top: 190px;
  max-width: 100%;
  z-index: 0;

  /* Extra small devices (phones, 640px and down) */
  @media only screen and (max-width: 640px) {
    padding-top: 10px !important;
    padding-bottom: 20px !important;
  }
`

export const BoxWhite = styled.div`
  background-color: #ccc;
  padding: 115px 10px;
  color: #1c3651;
  font-size: 50px;
  text-align: center;
  width: 100%;
  border: 1px solid #a5a5a5;
`

const Home = () => {
  const [reportData, setData] = useState({})
  const linkSupportedInstitutions =
    'https://forms.office.com/Pages/ResponsePage.aspx?id=melOhjjFoES93QRJ3mLfbe_x7lbJjvNLvRuNTzczcUdUOTZBUUlRTEhVSTNGVTNRNVUwSEU0U0wyNC4u&wdLOR=cF4F73CD6-717D-4A62-9791-3EC72893E01D'
  const { pathname } = useLocation()

  // TODO: remove this use Axios instead of fetch
  useEffect(() => {
    fetch(`/api/${pathname === '/' ? '2021/es' : pathname}`)
      .then(res => res.json())
      .then(data => setData(data))
    setTimeout(8000)
  }, [pathname])

  const data = isEmpty(reportData)

  return (
    <>
      {data && <SplashScreen />}
      {!data && (
        <Layout data={reportData}>
          <Banner bTitle={reportData.bTitle} year={reportData.year} />
          <Element name='aboutScroll' className='element'>
            <About aboutUs={reportData.aboutUs} />
          </Element>
          <ElementScroll
            marginResp='0'
            margin='-50px 0 0 0'
            name='odsScroll'
            className='element'>
            <ODS data={reportData.ods} />
          </ElementScroll>
          <Element name='InstitucionesScroll' className='element'>
            <SupportedInstitutions
              data={reportData.supportedInstitutions}
              linkTo={linkSupportedInstitutions}
              path={pathname.split('/')[1]}
            />
          </Element>
          <Element name='AlliancesScroll' className='element'>
            <Alliances data={reportData.alliances} />
          </Element>
          <Element style={{ marginTop: '-30px' }} name='ScopeScroll' className='element'>
            <ScopeProjects data={reportData.scopeProjects} />
          </Element>
          <Element style={{ marginTop: '-52px' }} name='MetricsScroll' className='element'>
            <Metrics data={reportData.metrics} year={reportData.year} />
          </Element>
          {reportData.civilAssociations && (
            <Element name='AsosiationsScroll' className='element'>
              <CivilAssociations data={reportData.civilAssociations} />
            </Element>
          )}
          <Element name='VolunteersScroll' className='element'>
            <Volunteers data={reportData.volunteers} />
          </Element>
        </Layout>
      )}
    </>
  )
}

export default Home
