import React from 'react'
import PropTypes from 'prop-types'
import {
  MetricContainer,
  MetricCardBack,
  MetricCardNumber,
  MetricCardCircle,
  RowCenter,
  MetricLabel,
  MetricImage,
  ColMetricCard
} from './MetricStyled'
import { Col, Row } from 'reactstrap'
import { classAnimateFadeIn } from '../../utils'

const MetricCard = ({ img, label, number }) => {
  return (
    <ColMetricCard>
      <MetricContainer className={classAnimateFadeIn}>
        <MetricCardBack>
          <RowCenter lg={1}>
            <MetricCardCircle>
              <MetricImage src={img} alt='ImgMetric' />
            </MetricCardCircle>{' '}
          </RowCenter>
          <RowCenter lg={1}>
            <MetricLabel>{label}</MetricLabel>
          </RowCenter>
          <Row lg={1} style={{ padding: '0px' }}>
            <MetricCardNumber>{number}</MetricCardNumber>
          </Row>
        </MetricCardBack>
      </MetricContainer>
    </ColMetricCard>
  )
}

MetricCard.propTypes = {
  img: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  number: PropTypes.string.isRequired
}

export default MetricCard
