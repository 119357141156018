import { Col, Row } from 'reactstrap'
import styled from 'styled-components'

export const MetricContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
  /* Extra small devices (phones, 640px and down) */
  @media only screen and (max-width: 640px) {
  }
`

export const ColMetricCard = styled(Col)`
  @media only screen and (max-width: 640px) {
    margin-left: 2px;
  }
`

export const RowCenter = styled(Row)`
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
  @media only screen and (max-width: 640px) {
    margin-left: 2px;
  }
`

export const MetricCardBack = styled.div`
  background: #ffffff78;
  max-width: 435px;
  margin-bottom: 20px;
  height: 160px;
  margin-top: 42px;
  border-radius: 11px;
  width: 85%;
  /* Extra small devices (phones, 640px and down) */
  @media only screen and (max-width: 640px) {
    height: 100px;
    width: 85%;
    padding: 0 0px;
    text-align: center;
    min-width: 215px;
    @media (max-width: 640px) {
      /* width: 111%; */
    }
  }
`
export const MetricLabel = styled.label`
  font-size: 19px;
  line-height: 20px;
  font-family: 'Montserrat';
  font-weight: 500;
  color: #fff;
  text-align: center;
  padding: 8px 0px 1px 0px;
  @media (max-width: 640px) {
    font-size: 13px;
    font-weight: 400;
  }
`
export const MetricCardCircle = styled.div`
  background: #ff8400;
  width: 70px;
  height: 70px;
  margin-top: 14px;
  border-radius: 50%;
  border: 0.5px solid #ffffff78;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
  padding: 0px;

  /* Extra small devices (phones, 640px and down) */
  @media only screen and (max-width: 640px) {
    width: 35px;
    height: 35px;
  }
`

export const MetricCardNumber = styled.div`
  background: #001830;
  color: #ff8400;
  height: 62px;
  margin-top: 11px;
  border-radius: 11px;
  width: 100%;
  font-size: 60px;
  font-weight: 700;
  font-family: 'Baloo 2';
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
  @media (max-width: 640px) {
    font-size: 33px;
    height: 45px;
    width: 100%;
    padding: 0 120px;
  }
`
export const MetricImage = styled.img`
  height: 68%;
  width: 68%;
  object-fit: cover;
`
