import styled from 'styled-components'

export const SplashScreenSpinner = styled.div`
  top: 0;
  bottom: 0;
  left: -3rem;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1c3651;
  z-index: 100;
  flex-direction: column;
  position: absolute;
  margin-left: 33px;
  padding-left: 33px;
  color: #fefefe;

  @media (max-width: 768px) {
    padding-left: 23px;
  }

  :: span {
    font-size: 2rem;
    animation: fade 1s linear 0s infinite;
    padding: 1rem;
  }
`

export const SplashScreenSpinnerImage = styled.img`
  width: 200px;
  margin: 0 !important;
`

export const SplashScreenSpinnerText = styled.span`
  font-size: 2rem;
  animation: fade 1s linear 0s infinite;
  padding: 1rem;
`

export const SplashScreenHalfSpinnerText = styled.div`
  width: 50px;
  height: 50px;
  border: 3px solid #377ebc;
  border-top: 3px solid transparent;
  border-radius: 50%;
  animation: spin 0.5s linear 0s infinite;
`
