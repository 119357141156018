import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { ContainerBody, Title, TitleVolunteers } from '../StyledComponents/styled'
import { Container, Col, Row } from 'reactstrap'
import {
  VolunteerQuote,
  VolunteerQuoteText,
  VolunteerQuoteAuthor,
  VolunteerImage,
  VolunteerImageMobbile,
  VolunteerSubTitle
} from './VolunteerStyled'
import imageVolunteer from './volunteer.webp'
import imageMobileVolunteer from './voluntariosmanos.webp'
import VolunteersList from './VolunteersList'

export const ContainerVolunteer = styled(Container)`
  ${props =>
    props.overflowH &&
    css`
      overflow: ${props.overflowH};
    `}
`

const Volunteers = ({ data }) => {
  return (
    <>
      <ContainerBody style={{ height: 'auto', background: '#1c3651' }}>
        <Container>
          <VolunteerQuote background={'#1c3651'}>
            <VolunteerQuoteText>
              {data?.volunteersQuote?.volunteersQuoteText}
            </VolunteerQuoteText>
            <VolunteerQuoteAuthor>
              {data?.volunteersQuote?.volunteersQuoteAuthor}
            </VolunteerQuoteAuthor>
          </VolunteerQuote>
        </Container>
      </ContainerBody>
      <ContainerBody style={{ height: 'auto', background: '#f8f9fb' }}>
        <ContainerVolunteer overflowH='hidden'>
          <Row style={{ padding: '0%' }}>
            <Col lg='7' style={{ paddingLeft: '0%' }}>
              {' '}
              <TitleVolunteers
                style={{ lineHeight: '48px' }}
                color='#ff8300'
                textAlign='left'
                padding='0px'
                margin='150px 0px 0px 0px'>
                {data?.volunteersTitle}
              </TitleVolunteers>
              <VolunteerSubTitle>{data?.volunteersSubTitle}</VolunteerSubTitle>
              {data?.volunteersList?.map((volunteer, index) => (
                <VolunteersList key={index} name={volunteer?.volunteersListName} />
              ))}
            </Col>
            <Col lg='5' style={{ maxWidth: '545px' }}>
              <VolunteerImage src={imageVolunteer} />
              <VolunteerImageMobbile src={imageMobileVolunteer} />
            </Col>
          </Row>
        </ContainerVolunteer>
      </ContainerBody>
    </>
  )
}

Volunteers.propTypes = {
  volunteers: PropTypes.array.isRequired,
  year: PropTypes.number.isRequired
}

export default Volunteers
