import React from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  Item,
  Image,
  BannerReport,
  BannerReportText,
  BannerReportTextGreen
} from './BannerStyled'
import pro from './../../images/Banner/pro.webp'
import bono from './../../images/Banner/bono.webp'
import img1 from './../../images/Banner/imagen1.webp'
import img2 from './../../images/Banner/imagen2.webp'
import img3 from './../../images/Banner/imagen3.webp'
import img4 from './../../images/Banner/imagen4.webp'
import img5 from './../../images/Banner/imagen5.webp'
import img6 from './../../images/Banner/imagen6.webp'
import img7 from './../../images/Banner/imagen7.webp'
import img8 from './../../images/Banner/imagen8.webp'
import img10 from './../../images/Banner/imagen9.webp'

import img9 from './Banner.webp'

import { classAnimateFadeIn } from '../../utils'
import { randomColor } from './banner.helpers.'

const Banner = ({ bTitle, year }) => {
  return (
    <>
      <Grid>
        <Image src={img1} />
        <Item color='#33cc99'></Item>
        <Image src={img2} />
        <Item color='#1c3651'></Item>
        <Image src={img3} />
        <Item color='#ff8300'></Item>
        <Item color='#1c3651' colorResp='#ededed'></Item>
        <Image src={img4} />
        <Item color='#ededed'></Item>
        <Image src={pro} />
        <Image src={bono} />
        <Image src={img5} />
        <Image src={img6} />
        <Item color='#FF8300'></Item>
        <Image src={img7} />
        <Item color='#33cc99'></Item>
        <Image src={img8} />
        <Item color='#ededed' colorResp='#1c3651'></Item>
      </Grid>
      <BannerReport bgImg={img9}>
        <BannerReportText className={classAnimateFadeIn}>
          {bTitle?.title} <BannerReportTextGreen>{year}</BannerReportTextGreen>
        </BannerReportText>
      </BannerReport>
    </>
  )
}

Banner.propTypes = {
  year: PropTypes.number.isRequired
}

Banner.defaultProps = {
  year: 2021
}

export default Banner
