import React from 'react'
import InstitutionsGrid from './InstitutionsGrid'
import { Title, Underline } from '../StyledComponents/styled'
import { Container } from 'reactstrap'
import styled from 'styled-components'

export const InstitutionParagraph = styled.p`
  font-family: 'Montserrat', sans-serif;
  color: '#1c3651';
  font-size: 20px;
  padding: 0 90px;
  margin-bottom: 30px;
  margin-top: 50px;
  /* Extra small devices (phones, 640px and down) */
  @media only screen and (max-width: 640px) {
    margin-top: 20px;
    padding: 0 10px;
  }
`

const SupportedInstitutions = ({ path, data, linkTo }) => {
  return (
    // <ContainerHome style={{ maxWidth: '100vw' }}>
    <>
      <Container>
        <Title textAlign='center' fontSmall='25px'>
          {data?.supportedInstitutionsTitle}
        </Title>
        <Underline margin='50%' transform='-50%' />
      </Container>
      <>
        <InstitutionParagraph fonts='20px'>
          {data?.supportedInstitutionsDescription}
        </InstitutionParagraph>
      </>
      {data?.supportedInstitutionsList && (
        <InstitutionsGrid
          list={data?.supportedInstitutionsList}
          nameSupported={data?.nameSupported}
          register={data?.supportedInstitutionsRegister}
          registerText={data?.supportedInstitutionsRegisterText}
          linkTo={linkTo}
          path={path}
        />
      )}
    </>
    // </ContainerHome>
  )
}

SupportedInstitutions.propTypes = {}

export default SupportedInstitutions
