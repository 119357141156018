import React from 'react'
import {
  ContainerBody,
  TitleMetricsMobile,
  TitleMetricsMobileYear,
  UnderlineMetrics
} from '../StyledComponents/styled'
import bgImg from './../../images/metricas/metrics.webp'
import PropTypes from 'prop-types'
import { Container, Row } from 'reactstrap'
import MetricCard from './MetricCard'
import { classAnimateFadeIn } from '../../utils'

const Metrics = ({ data, year }) => {
  return (
    <>
      <ContainerBody bgImg={bgImg} style={{ height: 'auto' }}>
        <Container className={`${classAnimateFadeIn} justify-content-center`}>
          <TitleMetricsMobile
            color='#FFFFFF'
            textAlign='left'
            padding='30px'
            margin='50px 0px 5px -30px'>
            {data?.metricsTitle}
          </TitleMetricsMobile>
          <TitleMetricsMobileYear
            color='#ff8300'
            textAlign='left'
            padding='0px'
            margin='-14px 0px -20px -30px'>
            {year}
          </TitleMetricsMobileYear>
          <UnderlineMetrics
            margin='50%'
            transform='-50%'
            color='#33CC99'
            allMargin={'0px 0 0 5px'}
          />
          <Row lg={2} sm={1} style={{ padding: '0 15%', marginLeft: '-8px' }}>
            {data?.metricsItems?.map((metric, index) => (
              <MetricCard
                key={index}
                img={metric.metricsItemsImg}
                label={metric.metricsItemsLabel}
                number={metric.metricsItemsNumber}
              />
            ))}
          </Row>
        </Container>
      </ContainerBody>
    </>
  )
}

Metrics.propTypes = {
  data: PropTypes.array.isRequired,
  year: PropTypes.number.isRequired
}

export default Metrics
