import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Container } from 'reactstrap'
import { isEmpty } from '../../utils'
import Layout from '../Layout'
import { RowCenter } from '../Metrics/MetricStyled'
import { SplashScreen } from '../SplashScreen/SplashScreen'
import { BackArrow, BackLink, RowCenterTeam, TextContainer, TitleTeam, Underline } from '../StyledComponents/styled'
import TeamList from '../Team/TeamList'
import TeamRo from '../Team/TeamRo'
import { ContainerHome } from './Home'
import arrow from '../../images/Equipo/Equipo/left-arrow.webp'

function Team() {
  const [teamData, setTeamData] = useState({})

  const { pathname } = useLocation()
  const path = pathname.split('/')[2]
  const language = pathname.split('/')[3]

  useEffect(() => {
    fetch(`/api/${path === 'team' ? '2021/es' : path + '/' + language}`)
      .then(res => res.json())
      .then(data => setTeamData(data))
    setTimeout(8000)
  }, [pathname])

  const data = isEmpty(teamData)

 

  return (
    <>
      {data && <SplashScreen />}
      {!data && (
        <Layout data={teamData} navBar={teamData.navBar}>
          <ContainerHome>
            <Container>
              <TextContainer
                style={{ marginBottom: '40px', marginTop: '30px' }}
                textAlign='center'>
                <TitleTeam>{teamData?.navBar?.navBarOurTeam}</TitleTeam>
                <Underline margin='50%' transform='-50%' />
              </TextContainer>


              <RowCenterTeam xs={1} md={3} lg={5} xl={5}>
                {teamData.ourTeam &&
                  teamData?.ourTeam.map(
                    ({ id, ourTeamRole, ourTeamName, ourTeamImage }, index) => {
                      return index === 0 ? (
                        <>
                          <TeamRo key={id} name={ourTeamName} role={ourTeamRole} />
                          <div style={{ width: '100%', height: '5px' }} />

                        </>
                      ) : (
                        <TeamList
                          key={id}
                          role={ourTeamRole}
                          name={ourTeamName}
                          image={ourTeamImage}
                        />
                      )
                    }
                  )}
                </RowCenterTeam>
            </Container>
          </ContainerHome>
        </Layout>
      )}
    </>
  )
}

export default Team
