import React, { useEffect, useState } from 'react'
import parse from 'html-react-parser'
import PropTypes from 'prop-types'
import { Col, Row } from 'reactstrap'
import styled, { css } from 'styled-components'
import ReactCardFlip from 'react-card-flip'

export const InstitutionContainer = styled.section`
  display: grid;
  background: white;
  border: 1px solid #ccc;
  cursor: pointer;
  height: 285px;
  align-items: center;
  justify-items: center;
  ${props =>
    props.report2020 === '2020' &&
    css`
      cursor: auto;
    `}
  @media (max-width: 768px) {
    height: 251px;
  }

  @media (max-width: 1140px) and (min-width: 770px) {
    height: 349px;
  }
`

export const LastInstitutionContainer = styled.section`
  display: grid;
  background: white;
  border: 1px solid #ccc;
  cursor: pointer;
  padding: 5% 5% 5% 5%;
  height: 285px;
  align-items: center;
  justify-items: center;
  ${props =>
    props.report2020 === '2020' &&
    css`
      width: 100%;
    `}
  @media (max-width: 640px) {
    height: 95px;
    padding: 0 10px;
    width: 100%;
    margin-left: 11px;
    text-align: center;
  }
  @media (max-width: 1140px) and (min-width: 992px) {
    height: 349px;
  }
  @media (min-width: 769px) and (max-width: 991px) {
    height: 190px;
    padding: 0px 30px;
    width: 100vw;
  }
  @media (min-width: 641px) and (max-width: 768px) {
    height: 190px;
    padding: 0px 50px 0px 30px;
    margin-right: 0px;
    width: 100vw;
  }
`

export const InstitutionImage = styled.img`
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 235px;
  object-fit: cover;
  padding: 50px;
`

export const InstitutionCol = styled(Col)`
  transition: all 0.5s ease;
  padding: 0;
  height: 100%;
  cursor: pointer;
  ${props =>
    props.minWidth &&
    css`
      min-width: ${props.minWidth} !important;
    `}
  ${props =>
    props.report2020 === '2020' &&
    css`
      min-width: 25% !important;
    `}
  @media (max-width: 640px) {
    ${props =>
      props.minWidthResp &&
      css`
        min-width: ${props.minWidthResp} !important;
      `}
  }
`
export const LabelContent = styled(Row)`
  font-size: 12px;
  font-family: Montserrat;
  line-height: 16px;
  padding: 5px 36px 21px 38px;
  min-height: 191px;
`

export const LabelCountry = styled(Col)`
  color: rgb(51, 204, 153);
  font-family: 'Montserrat';
  font-weight: bold;
  font-size: 20px;
  padding: 17px 0 0 0;
  color: #33cc99;

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 17px 25px 0 25px;
    margin-left: 10px;
  }
`
export const LabelDescription = styled(Col)`
  color: rgb(51, 204, 153);
  font-family: 'Montserrat';
  font-weight: normal;
  font-size: 12px;
  padding: 3px 0 16px 0;
  color: #ffffff;
  line-height: 16px;
  @media (max-width: 768px) {
    font-size: 10px;
    padding: 17px 40px 0 25px;
    line-height: 10px;
    margin-top: -5px;
    margin-left: 10px;
  }
`

export const LabelDescription2 = styled(Col)`
  color: rgb(51, 204, 153);
  font-family: 'Montserrat';
  font-weight: normal;
  font-size: 12px;
  padding: 3px 0 16px 0;
  color: #ffffff;
  line-height: 16px;
  min-height: 172px;
  padding-top: 11px;

  @media (max-width: 768px) {
    min-height: 160px;
    font-size: 10px;
    padding: 17px 40px 0 25px;
    line-height: 10px;
    margin-top: -5px;
    margin-left: 10px;
  }
`

export const LabelApoyoProbono = styled.span`
  color: #fff;
  font-size: 13px;
  margin-bottom: 2px;
  font-weight: bold;
  @media (max-width: 768px) {
    font-size: 10px;
  }
`

const Institution = ({
  thumbnail,
  description,
  proBonoSupport,
  country,
  hideGrid,
  handleHideGrid,
  open,
  setOpen,
  name,
  path,
  nameSupported
}) => {
  const [isFlipped, setIsFlipped] = useState(false)
  const handleClick = e => {
    e.preventDefault()
    setIsFlipped(!isFlipped)
    !isFlipped === true && setOpen(name)
    handleHideGrid(!isFlipped === true)
  }

  useEffect(() => {
    setIsFlipped(open === name)
  }, [open, name])

  return (
    <>
      {path === '2020' ? (
        <InstitutionCol style={{ filter: hideGrid > 0 ? 'brightness(0.7)' : 'none' }}>
          <InstitutionContainer report2020={path}>
            <InstitutionImage alt='GTA2030 logo' src={thumbnail} />
          </InstitutionContainer>
        </InstitutionCol>
      ) : (
        <ReactCardFlip
          isFlipped={isFlipped}
          flipDirection='horizontal'
          flipSpeedBackToFront={0.2}
          flipSpeedFrontToBack={0.2}>
          <InstitutionCol
            onClick={path === '2020' ? null : handleClick}
            style={{ filter: hideGrid > 0 ? 'brightness(0.7)' : 'none' }}>
            <InstitutionContainer>
              <InstitutionImage alt='GTA2030 logo' src={thumbnail} />
            </InstitutionContainer>
          </InstitutionCol>

          <InstitutionCol
            onClick={handleClick}
            style={{ background: '#1c3651', color: '#fff' }}>
            <Row>
              <LabelCountry>{country}</LabelCountry>
            </Row>
            <Row>
              <LabelDescription2>{description}</LabelDescription2>
            </Row>
            {proBonoSupport && (
              <Row style={{ marginTop: '-4px' }}>
                <LabelDescription>
                  <LabelApoyoProbono>
                    {nameSupported || 'Apoyo Pro-Bono Softtek'}
                    {': '}
                  </LabelApoyoProbono>
                  {parse(proBonoSupport)}
                </LabelDescription>
              </Row>
            )}
          </InstitutionCol>
        </ReactCardFlip>
      )}
    </>
  )
}

Institution.propTypes = {
  thumbnail: PropTypes.string.isRequired
}

Institution.defaultProps = {
  thumbnail: require(`./../../images/instituciones/Ciudaddelosninos.webp`)
}

export default Institution
