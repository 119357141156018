import { Col, Row } from 'reactstrap'
import styled, { css } from 'styled-components'

export const StyledDiv = styled.div`
  display: none;
  z-index: -1;
  @media (max-width: 640px) and (orientation: landscape) {
    display: flex;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #f2f2f2;
    z-index: 999999999999;
    width: 100%;
    height: 100%;
    font-size: 120%;
    align-items: center;
    justify-content: center;
  }
`
export const StyledText = styled.p`
  @media (max-width: 640px) and (orientation: landscape) {
    color: #000;
    text-align: center;
    margin-top: 30px;
    font-weight: bold;
  }
`
export const StyledImg = styled.img`
  @media (max-width: 640px) and (orientation: landscape) {
    width: 50px;
    margin-left: 26px !important;
  }
`
export const StyledContainer = styled.div`
  @media (max-width: 640px) and (orientation: landscape) {
    margin: 0;
  }
`
