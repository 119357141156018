import styled, { css } from 'styled-components'

export const VolunteerQuote = styled.section`
  margin-top: 0px;
  width: 100%;
  padding: 54px 90px;
  ${props =>
    props.background &&
    css`
      background: ${props.background};
    `}

  /* Extra small devices (phones, 640px and down) */
  @media only screen and (max-width: 640px) {
    padding: 5px;
  }

  /* @media screen and (max-width: 768px) {
    padding: 54px 20px;
  } */
`

export const VolunteerQuoteText = styled.label`
  color: #fff;
  font-family: 'Montserrat';
  text-align: center;
  font-size: 46px;
  font-weight: 900;
  line-height: 49px;
  width: 100%;
  padding-top: 23px;
  /* Extra small devices (phones, 640px and down) */
  @media only screen and (max-width: 640px) {
    font-size: 29px;
    line-height: 32px;
  }
`
export const VolunteerQuoteAuthor = styled.label`
  color: #e4e4e4;
  font-family: 'Montserrat';
  text-align: right;
  font-size: 28px;
  font-weight: lighter;
  line-height: 29px;
  width: 100%;
  padding: 15px 84px 0 27px;
  /* Extra small devices (phones, 640px and down) */
  @media only screen and (max-width: 640px) {
    font-size: 15px;
    font-weight: 100;
    line-height: 32px;
    padding-right: 10px;
  }
  /* @media screen and (max-width: 768px) {
    padding: 15px 64px 0 27px;
  } */
`
export const VolunteerLabelList = styled.label`
  color: #1c3651;
  font-family: 'Montserrat';
  text-align: left;
  font-size: 24px;
  font-weight: 500;
  line-height: 20px;
  width: 100%;
  padding: 0px 84px 14px 0px;
  margin: 0px 0px 0px 0px;
  /* Extra small devices (phones, 640px and down) */
  @media only screen and (max-width: 640px) {
    font-size: 16px;
    line-height: 23px;
    padding: 0px 56px 0px 0px;
    text-align: center;
    width: 93%;
    margin: 0px 0px 0px 50px;
  }
`
export const VolunteerSubTitle = styled.label`
  color: #1c3651;
  font-weight: 700;
  font-family: 'Baloo 2';
  text-align: left;
  font-size: 30px;
  line-height: 29px;
  width: 100%;
  padding: 0px 4px 34px 0px;
  margin: 0px 0px 0px 0px;

  /* Extra small devices (phones, 640px and down) */
  @media only screen and (max-width: 640px) {
    line-height: 20px;
    font-size: 25px;
    font-weight: 700 !important;
    padding: 0px 71px 30px 0px;
    margin: 0px 0px 0px 50px;
  }
`
export const VolunteerImage = styled.img`
  width: auto;
  height: 801px;
  display: block;
  margin-top: 20px;
  object-fit: cover;
  /* Extra small devices (phones, 640px and down) */
  @media only screen and (max-width: 640px) {
    height: 401px;
    padding: 5px 10% 0px 20%;
    display: none;
  }
`

export const VolunteerImageMobbile = styled.img`
  width: auto;
  height: 701px;
  display: none;
  margin-top: 20px;
  object-fit: cover;

  /* Extra small devices (phones, 640px and down) */
  @media only screen and (max-width: 640px) {
    display: block;
    height: 187px;
    margin: 0 auto;
  }
`
