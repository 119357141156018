import React, { useEffect } from 'react'
import { Provider } from 'react-redux'
import configureStore from './redux/configureStore'
import Router from './components/Router'
import Aos from 'aos'
import 'aos/dist/aos.css'
import ScrollOut from 'scroll-out'
import { Helmet } from 'react-helmet'
import favicon from './images/favicon.ico'
import 'animate.css'
import { StyledContainer, StyledDiv, StyledImg, StyledText } from './index.styled'
import rotate from './images/screenrotate.png'

const store = configureStore()

if (process.env.REACT_APP_ENV !== 'production') {
  // env
  console.log(process.env.REACT_APP_ENV)
  window.store = store
  window.state = store.getState()
}

function App() {
  useEffect(() => {
    Aos.init({ duration: 3000 })
    ScrollOut({
      targets: '#scrolltarget'
    })
    ScrollOut({
      targets: '.sticky-header,.list-team-members,#esfuerzoChart,.Chart',
      cssProps: {
        viewportY: true,
        visibleY: true
      }
    })
  })

  const ldJson = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    url: 'https://probono.softtek.cloud/',
    logo: { favicon }
  }
  return (
    <Provider store={store}>
      <StyledDiv>
        <StyledContainer>
          <StyledImg src={rotate} alt='rotate' />
          <StyledText>
            ¡Gira el móvil! <br /> Por favor
          </StyledText>
        </StyledContainer>
      </StyledDiv>
      <Router />
      <Helmet>
        <title>Pro-Bono Reporte Anual</title>
        <meta name='description' content='' />
        <meta
          name='keywords'
          content='Softtek, Pro bono, Reporte Pro bono, RAnual, Pro-bono'
        />
        <meta name='author' content='CSR - Pro-Bono - Desarrollo' />
        <meta
          name='copyright'
          content='&copy; Valores Corporativos Softtek S.A. de C.V. 2021'
        />
        <script type='application/ld+json'>{JSON.stringify(ldJson)}</script>
      </Helmet>
    </Provider>
  )
}

export default App
