import React from 'react'
import {
  PRo,
  ColCardRo,
  ImageRo,
  RowCardRo,
  PRoRole,
  TeamCardRo,
  TeamLabelRo
} from './TeamStyled'

import img from './../../images/Equipo/Equipo/rosario.webp'

const TeamRo = ({ role, name }) => {
  return (
    <TeamCardRo>
      <RowCardRo>
        <ColCardRo style={{ maxWidth: '180px' }}>
          <ImageRo src={img} />{' '}
        </ColCardRo>
        <ColCardRo>
          <TeamLabelRo>
            <PRo>{name}</PRo>
            <PRoRole>{role}</PRoRole>{' '}
          </TeamLabelRo>
        </ColCardRo>
      </RowCardRo>
    </TeamCardRo>
  )
}

TeamRo.propTypes = {}

export default TeamRo
